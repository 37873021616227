<template>
  <div>
    <el-row class="bgW">
      <el-col :span="18">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="店铺名称">
            <el-select
              v-model="search.shopId"
              clearable
              remote
              filterable
              :remote-method="remoteShop"
              placeholder="请输入店铺名称"
              class="w200"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
           <el-form-item label="日期">
            <!-- <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker> -->
            <el-date-picker
              v-model="search.start_time"
              type="month"
              class="w130"
              placeholder="日期"
              value-format="yyyy-MM-1"
            ></el-date-picker>
          </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="6" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button  type="primary" :icon="task.icon" size="mini" @click="addTask('search')" :disabled="task.btn_status">{{task.msg}}</el-button>
        <el-button type="primary" class="mb10" size="mini" :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
      </el-row>

    <div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            max-height="700"
            border
            v-loading="loading"
            :span-method="objectSpanMethod"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="姓名" prop="name" />
            <ElTableColumn label="级别" prop="gradeName" />
            <ElTableColumn label="项目名称" prop="goodsName"  width="100"/>
            <ElTableColumn label="本月合计" prop="sum" />
            <el-table-column
              label="提成标准">
              <template slot-scope="scope">
                <el-input v-model="scope.row.commission" placeholder="提成标准"  @blur="handleInputReset(scope.row)"></el-input>
              </template>
            </el-table-column>
            <ElTableColumn label="本月提成" prop="commissionMoney" />
            <ElTableColumn label="1日" prop="d01" />
            <ElTableColumn label="2日" prop="d02" />
            <ElTableColumn label="3日" prop="d03" />
            <ElTableColumn label="4日" prop="d04" />
            <ElTableColumn label="5日" prop="d05" />
            <ElTableColumn label="6日" prop="d06" />
            <ElTableColumn label="7日" prop="d07" />
            <ElTableColumn label="8日" prop="d08" />
            <ElTableColumn label="9日" prop="d09" />
            <ElTableColumn label="10日" prop="d10" />
            <ElTableColumn label="11日" prop="d11" />
            <ElTableColumn label="12日" prop="d12" />
            <ElTableColumn label="13日" prop="d13" />
            <ElTableColumn label="14日" prop="d14" />
            <ElTableColumn label="15日" prop="d15" />
            <ElTableColumn label="16日" prop="d16" />
            <ElTableColumn label="17日" prop="d17" />
            <ElTableColumn label="18日" prop="d18" />
            <ElTableColumn label="19日" prop="d19" />
            <ElTableColumn label="20日" prop="d20" />
            <ElTableColumn label="21日" prop="d21" />
            <ElTableColumn label="22日" prop="d22" />
            <ElTableColumn label="23日" prop="d23" />
            <ElTableColumn label="24日" prop="d24" />
            <ElTableColumn label="25日" prop="d25" />
            <ElTableColumn label="26日" prop="d26" />
            <ElTableColumn label="27日" prop="d27" />
            <ElTableColumn label="28日" prop="d28" />
            <ElTableColumn v-if="days>=29" label="29日" prop="d29" />
            <ElTableColumn v-if="days>=30" label="30日" prop="d30" />
            <ElTableColumn v-if="days>=31" label="31日" prop="d31" />
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import { getShopList } from "@/api/shop";
import pagination from "@/components/Pagination";
import { handleExportExcel } from "@/vendor/handleExportExcel";
import { staffScore,updatePersonCommission,addTaskQueue,getTaskQueueStatus } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  shopId = "";
  start_time = "";
  end_time = "";
}
class Options {
  shop_id = [];
}
class Task{
  status=0;
  msg="等待";
  btn_status=true;
  icon="el-icon-loading";
}
export default {
  name: "StaffResult",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(),
      page: new Page(), // 分页
      task: new Task(),
      list: [], // 数据展示
      loading: false,
      exportS: false,
      searchTime:'',
      days:0,
    };
  },
  mounted() {
     let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    let day = new Date().getDate()

    if(month.toString().length == 1){
      month = '0'+ month
    }
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-' + "1"
    this.searchTime = day
    this.getList('search');
    const timer = setInterval(()=>{
      getTaskQueueStatus({ task:"ShopPersonStatistics",shopId:this.search.shopId }).then(res => {
          if(res.data.status==2&&this.task.status!=2)
          {
            this.getList('search');
          }
          this.task.status=res.data.status;
          this.task.msg=res.msg;
          if(res.data.status==2)
          {
            this.task.btn_status=false;
            this.task.icon="el-icon-refresh";
          }else{
             this.task.btn_status=true;
             this.task.icon="el-icon-loading";
          }

      });
    },5*1000);
    this.days = this.mGetDate();
    this.$once('hook:beforeDestroy', () => {   //再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
      clearInterval(timer);
    })
    // clearTimeout(timer);
  },
  computed: {
    tableData() {
      const res = this.list.reduce((res, item) => {
        item.maxnum = 0;
        if (item.goods && item.goods.length > 0) {
          const goods = item.goods.map(goods => Object.assign({}, item, goods));
          goods[0].maxnum = item.goods.length;
          res = res.concat(goods);
        } else {
          res.push(item);
        }
        return res;
      }, []);
      return res;
    }
  },
  methods: {
    getList(option) {
      this.loading = true
      if (option === "search") this.page = new Page();
      staffScore({ ...this.search,page:1, limit:1000000}).then(res => {
        let str = this.search.end_time.substring(this.search.end_time.length-2)
        let strF = str.substr(0, 1)
        this.searchTime =  strF == 0?this.search.end_time.substring(this.search.end_time.length-1):str;
        this.page.total = res.dataCount;
        this.list = res.list;
        this.loading = false
      });
    },
    remoteShop(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0||columnIndex === 1) {
          if (rowIndex % 7 === 0) {
            return {
              rowspan: 7,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      },
    mGetDate(){
         var date = new Date();
         var year = date.getFullYear();
         var month = date.getMonth()+1;
         var d = new Date(year, month, 0);
         return d.getDate();
    },
    // 导出表格
    async exportExcel() {
      this.exportS = true
      const data= await staffScore({
        ...this.page,start_time:this.search.start_time
      });
      const list = data.list;
      handleExportExcel(
        [
          "姓名",
          "级别",
          "项目名称",
          // this.search.start_time,
          "本月合计",
          "提成标准",
          "本月提成",
          "1日",
          "2日",
          "3日",
          "4日",
          "5日",
          "6日",
          "7日",
          "8日",
          "9日",
          "10日",
          "11日",
          "12日",
          "13日",
          "14日",
          "15日",
          "16日",
          "17日",
          "18日",
          "19日",
          "20日",
          "21日",
          "22日",
          "23日",
          "24日",
          "25日",
          "26日",
          "27日",
          "28日",
          "29日",
          "30日",
          "31日",
        ],
        [
          "name",
          "gradeName",
          "goodsName",
          // "thirdOneDay",
          "sum",
          "commission",
          "commissionMoney",
          "d01",
          "d02",
          "d03",
          "d04",
          "d05",
          "d06",
          "d07",
          "d08",
          "d09",
          "d10",
          "d11",
          "d12",
          "d13",
          "d14",
          "d15",
          "d16",
          "d17",
          "d18",
          "d19",
          "d20",
          "d21",
          "d22",
          "d23",
          "d24",
          "d25",
          "d26",
          "d27",
          "d28",
          "d29",
          "d30",
          "d31",
        ],
        list,
        "员工业绩"
      );
      this.exportS = false
    },
    handleInputReset(row){
      updatePersonCommission({ id: row.id, commission: row.commission }).then(res => {
        this.getList('search');
      });
    },
    addTask(){
      this.task.status=0;
      this.task.msg="待执行";
      this.task.btn_status=true;
      this.task.icon="el-icon-loading";
      addTaskQueue({ task:"ShopPersonStatistics",shopId:this.search.shopId }).then(res => {
          if(res.code == 200)
          {
            this.$message({
              message: res.msg,
              type: 'success'
            });
          }else{
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.btnBox {
  text-align: right;
}
</style>
